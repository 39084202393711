import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconBolt = (props: IconType) => {
    const {
        width = '15',
        height = '15',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconBolt',
        stroke = "navy",
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(
                <path
                    d="m14.4320852.97163363c-.1271603-.29076187-.4024126-.47163363-.7187434-.47163363h-6.11808432c-.26086174 0-.50393151.12960724-.650192.34649561l-5.31011935 7.88711289c-.16300588.24238919-.17948962.55339397-.0431717.81181976.13684121.25842578.40241259.41879173.69362534.41879173h2.46418843l-3.14028345 4.71581051c-.14259744.2139965-.1138163.4892476.06985967.6690678.10230385.0999001.23234225.1509017.36395053.1509017.10492032 0 .21062558-.032599.30377179-.0985856l10.73065356-7.63709977c.1868158-.13302487.2655714-.36963037.196235-.5886219-.069598-.21899154-.270281-.36621274-.4989602-.36621274h-3.04556731l4.56207151-4.98711814c.214027-.23371366.2679262-.55996635.1407659-.85072822z"
                    fill={fill}
                    stroke={stroke}
                    strokeWidth=".9375"
                />
            )}
        />
    );
};

export default IconBolt;
