import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconHourglass = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconHourglass',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(3 .5)">
                    <g stroke="#070c63" strokeWidth=".9375">
                        <path d="m0 .576923h10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="m0 14.423077h10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="m1.11111111.57692308v2.88461538c0 2.23038462 1.74111111 4.03846154 3.88888889 4.03846154s3.88888889-1.80807692 3.88888889-4.03846154v-2.88461538"
                            strokeLinecap="square"
                        />
                        <path
                            d="m1.11111111 14.4230769v-2.8846154c0-2.23038458 1.74111111-4.0384615 3.88888889-4.0384615s3.88888889 1.80807692 3.88888889 4.0384615v2.8846154"
                            strokeLinecap="square"
                        />
                    </g>
                    <path
                        d="m5 10.3125c-1.22555556 0-2.22222222 1.035-2.22222222 2.3076923v2.3076923h4.44444444v-2.3076923c0-1.2726923-.99666666-2.3076923-2.22222222-2.3076923z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                    <path
                        d="m2.77777778 3.46153846c0 1.27269231.99666666 2.30769231 2.22222222 2.30769231s2.22222222-1.035 2.22222222-2.30769231z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                </g>
            )}
        />
    );
};

export default IconHourglass;
