import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconWatch = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconWatch',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(
                <g fill="none" fill-rule="evenodd" transform="translate(.5 .5)">
                    <circle cx="7.5" cy="7.5" r="7.5" stroke="#070c63" stroke-width=".75"/>
                    <circle cx="7.5" cy="7.5" r="5.357143" stroke="#070c63" stroke-width=".75"/>
                    <path d="m6.94982143 6.98410714 3.24715987-2.30298008-2.18108844 3.36905151z" fill="#070c63"
                          fill-rule="nonzero"/>
                    <circle cx="7.5" cy="7.5" fill="#070c63" fill-rule="nonzero" r="1.071429"/>
                    <g stroke="#070c63" stroke-linecap="round" stroke-linejoin="round" stroke-width=".75">
                        <path d="m7.5 2.142857v.535714"/>
                        <path d="m7.5 12.321429v.535714"/>
                        <path d="m12.857143 7.5h-.535714"/>
                        <path d="m2.678571 7.5h-.535714"/>
                        <path d="m11.288036 3.711964-.37875.37875"/>
                        <path d="m4.090714 10.909286-.37875.37875"/>
                        <path d="m11.288036 11.288036-.37875-.37875"/>
                        <path d="m4.090714 4.090714-.37875-.37875"/>
                    </g>
                    <path d="m7.03553571 7.99875-1.72954875-2.56059334 2.69276304 1.59737905z" fill="#070c63"
                          fill-rule="nonzero"/>
                </g>
            )}
        />
    );
};

export default IconWatch;
