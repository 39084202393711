import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";

function IconArrowRight(props) {
    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(
                <path
                    d="m364.845351 294.112472v8.196826l11.154649-11.154649-11.154649-11.154649v8.196826h-10.845351v5.915646z"
                    fillRule="evenodd"
                    transform="translate(-354 -280)"
                />
            )}
        />

    );
}

// Specify the default values for icon:
IconArrowRight.defaultProps = {
    width: '22',
    height: '22',
    fill: '#070c63',
    viewBox: '0 0 22 22',
    title: 'IconArrowRight',
};

export default IconArrowRight;
