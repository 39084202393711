import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {userLoginMethods} from "@User/constants/userLoginMethods";
import updateArrayMember from "@Array/updateArrayMember";
import CheckOrderResponseType from "@CheckOrder/types/CheckOrderResponse.type";
import PassengerType from "@CheckOrder/types/Passenger.type";
import CheckOrderTicketType from "@CheckOrder/types/CheckOrderTicket.type";
import {emptyPhoneCountryObject} from "@Features/input/phoneField/constants/emptyPhoneCountryObject";
import {gdprConsentPreferences} from "@CheckOrder/models/gdprConsentPreferences";
import VehicleAddonType from "@CheckOrder/types/VehicleAddon.type";
import {CheckOrderUserMessageType} from "@CheckOrder/types/CheckOrderUserMessage.type"
import {checkOrderInitialState} from "@CheckOrder/reducers/constants/checkOrderInitialState";
import CheckOrderStateType from "@CheckOrder/reducers/types/CheckOrderState.type";
import UpdateAdditionalFieldPassengerValueType
    from "@CheckOrder/reducers/types/UpdateAdditionalFieldPassengerValue.type";

const initialState = checkOrderInitialState as CheckOrderStateType

const checkOrderSlice = createSlice({
    name: 'checkOrder',
    initialState,
    reducers: {
        setCheckOrderData: (state, action: PayloadAction<CheckOrderResponseType | {
            ticket: CheckOrderTicketType,
            passengers: (PassengerType | VehicleAddonType)[]
        }>) => (
            {
                ...state,
                ...action.payload,
            }
        ),

        setCheckOrderDataFromUser: (state, action) => {
            const {firstName, lastName, country, phone, email} = action.payload
            const phoneNumber = state.phoneNumber || phone
            const passenger = {
                ...state.passengers[0],
                passengerFirstName: state?.passengers[0]?.passengerFirstName || firstName,
                passengerLastName: state?.passengers[0]?.passengerLastName || lastName,
                nationality: state?.passengers[0]?.nationality || country,
            }

            const passengers = updateArrayMember(
                state.passengers,
                0,
                'passengerFirstName',
                passenger,
                true
            )

            return {
                ...state,
                phoneNumber,
                passengers,
                email,
                emailValidated: true,
            }
        },

        updateCsrfToken: (state, action) => {
            const {
                ipgFormToken,
                token: csrfTokenBuyerInfo,
            } = action.payload.data.data

            return {
                ...state,
                tokens: {
                    ipgFormToken,
                    csrfTokenBuyerInfo,
                },
            }
        },

        setCheckOrderPassengers: (state, action) => (
            {...state, passengers: action.payload}
        ),

        setIsCheckOrder: (state, action: PayloadAction<boolean>) => (
            {...state, isCheckOrder: action.payload}
        ),

        setCheckOrderFatalError: (state, action: PayloadAction<boolean>) => (
            {...state, errorOccurred: action.payload}
        ),

        setUserConfirmed: (state, action) => ({
            ...state,
            userConfirmed: action.payload,
            submitTimestamp: Date.now(),
        }),

        setTicket: (state, action) => (
            {...state, ticket: action.payload}
        ),

        setTicketExpired: (state, action) => (
            {...state, ticketExpired: action.payload}
        ),

        setPhoneNumber: (state, action) => (
            {...state, phoneNumber: action.payload}
        ),

        setEmail: (state, action) => (
            {...state, email: action.payload}
        ),

        checkPasswordValidation: (state, action) => (
            {...state, passwordValidated: action.payload}
        ),

        checkPhoneValidation: (state, action: PayloadAction<boolean>) => {
            return {...state, phoneValidated: action.payload}
        },

        checkEmailValidation: (state, action) => ({
            ...state,
            emailValidated: action.payload,
        }),

        setPhoneCountryObject: (state, action) => ({
            ...state,
            phoneCountryObject: action.payload,
            phoneCountrySelectActive: false,
        }),

        clearLoggedInUserData: (state) => {
            const passenger = {
                ...state.passengers[0],
                passengerFirstName: '',
                passengerLastName: '',
                nationality: '',
            }

            const passengers = updateArrayMember(
                state.passengers,
                0,
                'passengerFirstName',
                passenger,
                true
            )

            return {
                ...state,
                passengers,
                phoneNumber: '',
                email: '',
                gdprConsentPreferences,
                phoneCountryObject: emptyPhoneCountryObject,
                password: '',
                userLoginMethods,
            }
        },

        clearInvalidVoucherState: (state) => ({
            ...state,
            ticket: {
                ...state.ticket,
                voucher: {
                    ...state.ticket.voucher,
                    ticketVoucher: null,
                },
            },
        }),

        setActiveTicket: (state, action: PayloadAction<string | null>) => (
            {...state, activeTicket: action.payload}
        ),

        setPassword: (state, action) => (
            {...state, password: action.payload}
        ),

        setPassengerDataUpdating: (state, action) => (
            {...state, passengerDataUpdating: action.payload}
        ),

        setStripeClientSecret: (state, action) => (
            {...state, stripeClientSecret: action.payload}
        ),

        addPassengerToResolvedList: (state, action) => (
            {...state, resolvedPassengers: [...state.resolvedPassengers, action.payload]}
        ),

        setPassengersResolved: (state, action) => ({
            ...state,
            passengersResolved: action.payload,
        }),

        toggleTripReview: (state) => ({
            ...state,
            tripReviewAccepted: !state.tripReviewAccepted,
        }),

        clearPassengerSubmissionData: (state) => ({
            ...state,
            passengersResolved: false,
            passengerDataUpdating: false,
            resolvedPassengers: [],
            userConfirmed: false,
            stripeClientSecret: null,
        }),

        setPaymentPendingLoader: (state, {payload}) => ({
            ...state,
            paymentPendingLoader: payload,
        }),

        setAncillaries: (state, {payload}) => ({
            ...state,
            ancillaries: payload,
        }),

        updateAdditionalFieldPassengerValue: (state, action: PayloadAction<UpdateAdditionalFieldPassengerValueType>) => {
            state.passengers[action.payload.passengerIndex].additionalInfo[action.payload.fieldName] = action.payload.value
        },
        setCheckOrderUserMessages: (state, action: PayloadAction<CheckOrderUserMessageType[]>) => {
            state.checkOrderUserMessages = action.payload
        },

        showCheckOrderModals: (state, action: PayloadAction<boolean>) => {
            state.checkOrderModalsVisible = action.payload
        },

        clearCheckOrderData: () => initialState,

        setIsBuyingPremiumSupport: (state, action) => {
            state.selectedServices.isBuyingPremiumSupport = action.payload;
        },

        setIsBuyingSMSNotification: (state, action) => {
            state.selectedServices.isBuyingSMSNotification = action.payload;
        },

        setIsBuyingTier1FlexibleCancellation: (state, action) => {
            state.selectedServices.isBuyingTier1FlexibleCancellation = action.payload;
        },
    },
})

export const {
    setCheckOrderData,
    setCheckOrderPassengers,
    setIsCheckOrder,
    setCheckOrderFatalError,
    clearCheckOrderData,
    setUserConfirmed,
    setTicketExpired,
    updateCsrfToken,
    setPhoneNumber,
    setEmail,
    setPhoneCountryObject,
    checkPasswordValidation,
    checkPhoneValidation,
    checkEmailValidation,
    clearLoggedInUserData,
    setActiveTicket,
    setPassword,
    setPassengerDataUpdating,
    setCheckOrderDataFromUser,
    setTicket,
    clearInvalidVoucherState,
    addPassengerToResolvedList,
    setPassengersResolved,
    setStripeClientSecret,
    clearPassengerSubmissionData,
    toggleTripReview,
    setPaymentPendingLoader,
    updateAdditionalFieldPassengerValue,
    setCheckOrderUserMessages,
    setAncillaries,
    setIsBuyingPremiumSupport,
    setIsBuyingSMSNotification,
    setIsBuyingTier1FlexibleCancellation,
    showCheckOrderModals,
} = checkOrderSlice.actions

export default checkOrderSlice.reducer