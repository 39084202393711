import React from 'react';
import PropTypes from "prop-types";
import GenericSvgIcon from "./GenericSvgIcon";

const IconThunderbolt = (props) => (
    <GenericSvgIcon
        {...props}
        svgIconPath={(
            <path
                d="m8.8046875 15.5 4.375-6.125h-2.625l.8203125-4.375-4.375 6.125h2.625z"
                fill="#f03a47"
                transform="translate(-7 -5)"
            />
        )}
    />

);

IconThunderbolt.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string,
    viewBox: PropTypes.string,
    title: PropTypes.string,
};

// Specify the default values for icon:
IconThunderbolt.defaultProps = {
    width: '7',
    height: '11',
    fill: '#fff',
    viewBox: '0 0 7 11',
    title: 'IconThunderbolt',
};

export default IconThunderbolt;
