import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconRisingSun = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconRisingSun',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(

                <g fill="none" fill-rule="evenodd" stroke="#070c63" transform="translate(0 3)">
                    <path
                        d="m8 4.34845361c1.05138026 0 2.0041127.43787064 2.6934187 1.14850199.8079043.83289886 1.2531958 2.04119695 1.0628787 3.35049801h-7.51259479c-.19031714-1.30930106.25497441-2.51759915 1.06287873-3.35049801.68930594-.71063135 1.6420384-1.14850199 2.69341866-1.14850199z"
                        stroke-width="1.2"/>
                    <g fill="#070c63" fill-rule="nonzero" stroke-width=".5">
                        <path
                            d="m8.0000625 2.06207499c.13799941 0 .25000107-.1154762.25000107-.25775938v-1.54655624c0-.14228317-.11200166-.25775937-.25000107-.25775937s-.24999893.1154762-.24999893.25775937v1.54655624c0 .14228318.11199952.25775938.24999893.25775938z"/>
                        <path
                            d="m2 8.24749064c0-.14214517-.11200806-.25750937-.25000747-.25750937h-1.4999936c-.13799941 0-.24999893.1153642-.24999893.25750937 0 .14214516.11199952.25750936.24999893.25750936h1.4999936c.13799941 0 .25000747-.1153642.25000747-.25750936z"/>
                        <path
                            d="m15.7500011 7.98998127h-1.4999936c-.1379994 0-.249999.1153642-.249999.25750937 0 .14214516.1119996.25750936.249999.25750936h1.4999936c.1379994 0 .2500074-.1153642.2500074-.25750936 0-.14214517-.112008-.25750937-.2500074-.25750937z"/>
                        <path
                            d="m5.4270891 2.36976038c.03924856.10050598.13299512.16132499.23199149.16132499.03124886 0 .0627477-.00618499.09349658-.01881266.1279953-.05334548.18999303-.20358905.13824493-.33553536l-.37473627-.95557998c-.0517481-.13194632-.19724277-.1953424-.32548806-.14251233-.12799531.05334548-.18999304.20358904-.13824494.33553536z"/>
                        <path
                            d="m10.2477978 2.51216704c.0307489.01262675.0624977.01881129.0934966.01881129.0989964 0 .1927429-.06081459.2319915-.16131326l.3747363-.95551045c.0517481-.13193672-.0102497-.28216935-.138245-.33551096-.1279953-.05308391-.2737399.01056525-.325488.14250197l-.3747363.95551045c-.0517481.13193672.0102496.28216935.1382449.33551096z"/>
                        <path
                            d="m2.43671482 5.41027288c-.05173969-.1319837-.19696074-.19539775-.32543513-.14255271l-.92681527.38641323c-.12797449.0533606-.18996214.20364673-.13822245.33563043.03924218.10053447.1329735.1613707.23195377.1613707.03124377 0 .06273749-.00618673.09348136-.01881798l.92681527-.38641323c.12797449-.05361838.18996214-.20390451.13822245-.33563044z"
                            transform="matrix(1 0 0 -1 0 11.4002)"/>
                        <path
                            d="m14.8155997 5.65403724-.9268827-.38632151c-.1282338-.05334793-.2737154.0105665-.3254588.14251888-.0517435.13195237.0102487.28220283.1382325.33555077l.9268827.3863215c.0307461.01262826.0624921.01881353.0934882.01881353.0989874 0 .1927256-.0608218.2319706-.1613324.0514935-.13195238-.0102487-.28220284-.1382325-.33555077z"
                            transform="matrix(1 0 0 -1 0 11.3998)"/>
                        <path
                            d="m3.40382628 3.87351341c.0487537.0502569.11275855.07551421.1767634.07551421.06400486 0 .12800971-.02525731.1767634-.07551421.09775741-.10077154.09775741-.26365545 0-.36442698l-1.06083041-1.09353867c-.09775742-.10077153-.25576939-.10077153-.3535268 0-.09775741.10077154-.09775741.26365545 0 .36442698z"/>
                        <path
                            d="m12.4195353 3.94902762c.0640049 0 .1280097-.02525731.1767634-.07551421l1.0608304-1.09353867c.0977574-.10077153.0977574-.26365544 0-.36442698-.0977574-.10077153-.2557694-.10077153-.3535268 0l-1.0608304 1.09353867c-.0977574.10077153-.0977574.26365544 0 .36442698.0487537.05051463.1127586.07551421.1767634.07551421z"/>
                    </g>
                    <path d="m0 9.031h16" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            )}
        />
    );
};

export default IconRisingSun;
