import React, {useRef} from "react"
import GenericPopover from "@Popover/components/GenericPopover"
import IconMTicketQr from "@Icons/IconMTicketQr"
import useIsDesktop from "@Hooks/selector/useIsDesktop"
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type"
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type"
import {useAppSelector} from "@Hooks/selector/useAppSelector"
import MTicketTransportTypeString from "@Route/components/MTicket/MTicketTransportTypeString"
import useAdditionalInfo from "@Route/hooks/useAdditionalInfo"
import MTicketEcoFriendlyLabel from "@Route/components/MTicket/MTicketEcoFriendlyLabel"
import useUser from "@User/hooks/useUser"
import IconInfo from "@Icons/IconInfo"
import Column from "@Containers/components/Column"
import Badge from "@Features/badge/components/Badge";
import IconThunderbolt from "@Icons/IconThunderbolt";
import Translation from "@Translation/components/Translation";

interface RouteMTicketProps {
    /** Route currently rendering */
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    displayTicketType?: boolean
}

function RouteMTicket(props: RouteMTicketProps) {
    const {isTablet, isSmallTablet, isMobile} = useAppSelector((state) => state?.page)
    const isDesktop = useIsDesktop()
    const additionalInfoActive = useAdditionalInfo()
    const user = useUser()
    const debugUsers = [2552, 822554]
    const ref = useRef()
    const showDebug = debugUsers.includes(user?.id)
    const {
        route: {
            id,
            status: {
                status,
                hasMTicket,
                isInstantConfirmation,
            },
            allotment: {
                totalAllotmentLeft,
            },
            type: {
                routeTransferType,
                vehicleType,
            },
        },
    } = props

    const hasVehicle = routeTransferType === 2 && Object.values(vehicleType).some((vehicle) => vehicle === true)

    const setClassName = () => {
        let className = ""

        if ((isMobile || isSmallTablet) && !additionalInfoActive) {
            className += " gb--badge gb--badge-left gb--badge-green"
        }

        if ((isMobile || isSmallTablet) && !additionalInfoActive && ((totalAllotmentLeft < 5 && status === "open") || hasVehicle)) {
            className += " gb--badge-mTicket"
        }

        return className
    }

    const Popover = () => (
        <span>
            <MTicketTransportTypeString />
        </span>
    )

    const Trigger = () => {
        const iconStyleAttributes = (!isMobile) ? {
            transform: "rotate(-10deg)",
        } : {
            width: "25px",
            height: "15px",
        }

        return (
            <span className={`gb--row ${setClassName()}`} style={{alignItems: "center"}}>
                <IconMTicketQr style={iconStyleAttributes} />
                <MTicketEcoFriendlyLabel
                    isSmall={!isDesktop}
                />
            </span>
        )
    }

    return (
        <div className={`gb--route-element gb--route-ticket_type ${isTablet ? "ticket--badge" : ""}`}>
            {props.displayTicketType && hasMTicket && (
                <GenericPopover
                    id={id}
                    overlay={Popover()}
                    trigger={Trigger()}
                />
            )}
            {isInstantConfirmation && (
                <Badge info>
                    <IconThunderbolt/>
                    <Translation translationKey="search_results.instant_confirmation"/>
                </Badge>
            )}
            {showDebug && (
                <>
                    <span className="gb--badge gb--badge-left gb--badge-green" style={{zIndex: 999}}>
                        Route provider:
                        {" "}
                        {props.route.status.providerId}
                    </span>
                    {ref && (
                        <>
                            <button onClick={() => ref?.current.showModal()}>
                                <IconInfo
                                    style={{cursor: "pointer"}}
                                />
                            </button>
                            <dialog
                                ref={ref}
                                onCancel={ref?.current?.hide}
                            >
                                <Column>
                                    <button
                                        onClick={(e) => {
                                            ref?.current.close()
                                            e.stopPropagation()
                                        }}
                                    >
                                        close
                                    </button>
                                    <br />
                                    <hr/>
                                    {props.route.status.jsonEncodedApiFullIntegrationData && (
                                        <div style={{maxWidth: '25em', fontSize: '22px'}}>
                                            {props.route.status.jsonEncodedApiFullIntegrationData}
                                        </div>
                                    )}
                                </Column>
                            </dialog>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default RouteMTicket
